import React, { useEffect, useState, useRef } from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../../../../css/jobDiscription.css';
import JDbannerImage from '../../../../css/images/jd-images/jd-bg-images.png';
import successIcon from '../../../../css/images/ThankYou-Icons/download.png';
import shareIcon from '../../../../css/images/jd-images/share-icon.svg';
import emailIcon from '../../../../css/images/jd-images/email-icon.svg';
import facebookIcon from '../../../../css/images/jd-images/facbook-icon.svg';
import linkedInIcon from '../../../../css/images/jd-images/linkedin-icon.svg';
import twitterIcon from '../../../../css/images/jd-images/twitter-icon.svg';
import bannerArrowIcon from '../../../../css/images/arrow-icon.svg';
import FollowUsModule from '../../../../Components/careers/careerInnerModule/FollowUs.js';
import APIService from '../../../../services/apiService.jsx';
import { Backdrop, Box, CircularProgress } from "@mui/material";
import ReactHtmlParser from 'react-html-parser';


const JobDiscriptionModule = () => {
  const location = useLocation();
  const [jobData, setJobData] = useState(null);
  const [fullName, setFullName] = useState("");
  const [experience, setExperience] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState("");
  const [formData, setFormData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const jobId = location.pathname.split("/").pop();

  console.log("location.pathname")

  const seoMetaDesceiption = `Apply for the ${jobData?.jobTitle} at The Higher Pitch. Be part of our team and contribute to innovative digital experiences for our audience.`
  const seoMetaKeyword = `${jobData?.jobTitle} Vacancy, Apply for ${jobData?.jobTitle}, Job opening for ${jobData?.jobTitle}`
  const navigate = useNavigate();
  /* Get Dynamic Data of Job Title*/
  useEffect(() => {

    const fetchJobData = async () => {
      try {
        const response = await APIService.view(`jobApiList`, jobId);
        console.debug('JobDiscriptionModule : fetchJobData jobApiList : response : ', response);
        setJobData(response.data);
      } catch (error) {
        console.error('Error fetching job data:', error);
      }
    };

    if (jobId) {
      fetchJobData();
    }
  }, []);
  console.debug("jobData", jobData)
  function onChangeHandler(e) {
    if (e.target.name === "fullName") {
      // Trim the input value to remove leading and trailing spaces
      const trimmedValue = e.target.value.trim();
      setFullName(trimmedValue);
    }
    if (e.target.name === 'email') {
      setEmail(e.target.value)
    }
    if (e.target.name === "phoneNumber") {
      setPhoneNumber(e.target.value)
    }
    if (e.target.name === "experience") {
      const trimmedValue = e.target.value.trim();
      setExperience(trimmedValue)
    }
    if (e.target.name === "file") {
      console.debug("selectdFile", e.target.files[0])
      setFile(e.target.files[0])
    }
  }

  /* Ends Here */
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    onSubmitHandler();
  };

  // };
  const onSubmitHandler = async () => {
    if (fullName && email && phoneNumber && experience && file) {
      const formData = new FormData();
      if (file) {
        formData.append('file', file);
      }
      if (fullName) {
        formData.append('firstName', fullName);
      }
      if (email) {
        formData.append('applicantEmail', email);
      }
      if (phoneNumber) {
        formData.append('phoneNumber', phoneNumber);
      }
      if (experience) {
        formData.append('experience', experience);
      }
      formData.append('source', "website");
      if (jobData) {
        formData.append('jobCode', jobData.jobCode);
      }
      if (jobId) {
        formData.append('jobId', jobId);
      }

      // if (jobData.hiringManager.userId) {
      //   formData.append('hiringManagerId', jobData.hiringManager.userId);
      // }
      // if (jobData.recruitingManager.userId) {
      //   formData.append('recruitingManagerId', jobData.recruitingManager.userId);
      // }
      setIsLoading(true); // Set loading state to true
      try {

        // Display form data in an alert
        const response = await APIService.post("createJobApplication", formData);

        if (response) {
          openSubmitModel();
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        // Handle error if needed
      } finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
      }

    } else {
      console.log("Please fill out all the required fields");
    }
  };

  const closeSubmitModalAndLogData = () => {
    closeSubmitModal(); // Close the modal
  };
  const modalRef = useRef(null);
  const submitModalRef = useRef(null);


  const openModal = () => {
    modalRef.current && modalRef.current.showModal();
  };

  const closeModal = () => {
    modalRef.current && modalRef.current.close();
  };

  const openSubmitModel = () => {
    submitModalRef.current && submitModalRef.current.showModal();
  };

  const closeSubmitModal = () => {
    submitModalRef.current && submitModalRef.current.close();
    window.location.reload();
  };

  /*  has scroll View method */
  const handleScroll = () => {
    const submitYourAppElement = document.getElementById('submitYourApp');
    if (submitYourAppElement) {
      submitYourAppElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  /* Ends Here */


  const renderExperience = (row) => {
    const { minExperience, experience } = row;
    if (minExperience != null && experience != null) {
      return `${minExperience} - ${experience}`;
    } else if (minExperience == null && experience != null) {
      return experience;
    } else {
      return '-';
    }
  };

  const share = (platform) => {
    const pageTitle = encodeURIComponent('Check out this page');
    const pageUrl = encodeURIComponent(window.location.href);

    let shareLink = '';

    switch (platform) {
      case 'email':
        shareLink = `mailto:?subject=${pageTitle}&body=${pageUrl}?source=email`;
        break;
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}?source=facebook`;
        break;
      case 'linkedin':
        shareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}?source=linkedin`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${pageUrl}&text=${pageTitle}?source=twitter`;
        break;
      default:
        break;
    }

    if (shareLink) {
      window.open(shareLink, '_blank');
    }
  };

  if (!jobData) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    </div>;
  }
  return (
    <div className="main-template" id='job-details-main-layout'>
      <Helmet>
        <title>{jobData.seoMetaTitle != null ? jobData.seoMetaTitle : "Join Our Team Today!"}</title>
        <meta name="description" content={jobData.seoMetaDescription != null ? jobData.seoMetaDescription : seoMetaDesceiption} />
        <meta name="keywords" content={jobData.seoKeywords != null ? jobData.seoKeywords : seoMetaKeyword} />
      </Helmet>
      <div className="banner_module">
        <img src={JDbannerImage} width="100%" height="auto" alt="Banner Image" />
        <div className="text-block job-details-content">
          {/* <Link to="/"> */}
          <div className='back-btn' onClick={() => navigate(-1)}>Back to all jobs</div>
          {/* </Link> */}
          <h2>{jobData.jobTitle}</h2>
          <div className='jd-post-details'>
            <div className='jd-text-block' id='publish-date'>Posted On <b>{new Date(jobData.created_at).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}</b></div>
            <div className='jd-text-block' id='experience'>Experience <b>{renderExperience(jobData)} years</b></div>
            {/* <div className='jd-text-block' id='location'>Location <b>{jobData.location.city.city.split(',').join(', ')}</b></div> */}
            <div className='jd-text-block' id='location'>Location <b>{jobData?.jobLocation?.map((d, i) => jobData.jobLocation.length == (i + 1) ? d?.city?.city : d?.city?.city + ",")}</b></div>
          </div>
          <Button variant="outline-light" onClick={handleScroll}>Apply <img src={bannerArrowIcon} width="15" height="auto" alt="arrow-icon" /></Button>
          <Button variant="outline-light" className='share-btn' onClick={openModal}>
            <img src={shareIcon} width="100%" height="auto" alt="Share Icon" />
          </Button>
          <dialog ref={modalRef} class="dialog">
            <button onClick={closeModal} class="dialog-close-btn">&times;</button>
            <h5>Share this job to your network</h5>
            <div className='jd-social-share'>
              <ul>
                <li><img src={emailIcon} onClick={() => share('email')} width="100%" height="auto" alt="Email Icon" /></li>
                <li><img src={facebookIcon} onClick={() => share('facebook')} width="100%" height="auto" alt="Facebook Icon" /></li>
                <li><img src={linkedInIcon} onClick={() => share('linkedin')} width="100%" height="auto" alt="LinkedIn Icon" /></li>
                <li><img src={twitterIcon} onClick={() => share('twitter')} width="100%" height="auto" alt="Twitter Icon" /></li>
              </ul>
            </div>
          </dialog>
        </div>
        <div class="blink-arrow">
          <a href="#jd-second-layer">
            <div id="mouse-scroll">
              <div>
                <span class="down-arrow-1"></span>
                <span class="down-arrow-2"></span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <Container fluid id='jd-second-layer'>
        <Row>
          <div className='jd-body-layout'>
            <div className='roles-and-resposibilities-layer'>
              <h3>Roles & Responsibilities</h3>
              <div>
                {jobData && ReactHtmlParser(jobData.jobDescription)}
              </div>
            </div>
          </div>

          <div className='submit_your-application-layer' id='submitYourApp'>
            <h4>Submit Your Application</h4>
            <div className='form-box-layout'>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      value={fullName}
                      name="fullName"
                      onChange={onChangeHandler}
                      placeholder="Full Name"
                    />
                    <Form.Control.Feedback type="invalid">Please Enter Name</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustom02">
                    <Form.Label>Email ID</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      value={email}
                      name="email"
                      onChange={onChangeHandler}
                      placeholder="Email ID"
                    />
                    <Form.Control.Feedback type="invalid">Enter Valid Email</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="4" controlId="validationCustomUsername">
                    <Form.Label>Contact</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="number"
                        value={phoneNumber}
                        name="phoneNumber"
                        onChange={onChangeHandler}
                        placeholder="Contact Number"
                        aria-describedby="inputGroupPrepend"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter valid contact.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                    <Form.Label>Experience</Form.Label>
                    <Form.Control type="text" value={experience} name="experience" onChange={onChangeHandler} placeholder="Experience" required />
                    <Form.Control.Feedback type="invalid">
                      Please enter experience.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="validationCustom05">
                    <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Upload Resume</Form.Label>
                      <Form.Control type="file" name="file" onChange={(e) => onChangeHandler(e)} required />
                      <Form.Control.Feedback type="invalid">
                        Please Uplaod Resume.
                      </Form.Control.Feedback>
                      <Form.Text className="text-muted">
                        Max file size: 5MB, Allowed types: PDF, DOCX
                      </Form.Text>
                    </Form.Group>
                  </Form.Group>
                </Row>
                <Button type="submit">{isLoading ? "Submitting..." : "Submit"}</Button>
              </Form>
              <div className="submit-dailog-model">
                <dialog ref={submitModalRef} className="dialog" id="submitModel">
                  <img src={successIcon} width="52" height="auto" alt="Success Icon" />
                  <h5>You have successfully Applied for <b>{jobData.jobTitle}</b></h5>
                  <Button onClick={closeSubmitModalAndLogData} className="dialog-close-btn" variant="outlined">
                    Ok
                  </Button>
                </dialog>
              </div>
            </div>
          </div>
          <div className='jd-follow-us ninth-layout'>
            <FollowUsModule />
          </div>
        </Row>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

    </div>
  );

}

export default JobDiscriptionModule;